import { ReactComponent as Logo } from "./Logo.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BackgroundSlider } from "./BackgroundSlider";
import { useCallback, useRef, useState } from "react";

function App() {
  const imageNames = Array.from({ length: 9 }, (_, i) => `img0${i + 1}.png`);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const activeIndexRef = useRef(0);

  const responsive = {
    lg: {
      breakpoint: { max: 3000, min: 1240 },
      items: 7,
    },
    md: {
      breakpoint: { max: 1240, min: 1024 },
      items: 5,
    },
    sm3: {
      breakpoint: { max: 1024, min: 764 },
      items: 4,
    },
    sm2: {
      breakpoint: { max: 764, min: 700 },
      items: 6,
    },
    sm: {
      breakpoint: { max: 700, min: 440 },
      items: 5,
    },
    xs: {
      breakpoint: { max: 440, min: 360 },
      items: 3,
    },
  };

  // const goToTarget = (targetIndex: number, direction: number) => {
  //   const update = setInterval(() => {
  //     activeIndexRef.current = activeIndexRef.current + direction;
  //     if (activeIndexRef.current > imageNames.length - 1) {
  //       activeIndexRef.current = 0;
  //     } else if (activeIndexRef.current < 0) {
  //       activeIndexRef.current = imageNames.length - 1;
  //     }

  //     setActiveIndex(activeIndexRef.current);
  //     if (activeIndexRef.current === targetIndex) {
  //       clearInterval(update);
  //     }
  //   }, 50);
  // };

  // const changeToIndexWithTimeout = (targetIndex: number) => {
  //   const direction =
  //     Math.abs(activeIndexRef.current - 0) >= imageNames.length / 2 ? -1 : 1;

  //   goToTarget(targetIndex, direction);
  // };

  return (
    <div className="h-screen bg-cover bg-center bg-no-repeat flex md:flex-row md:justify-end flex-col justify-end">
      <div className="overflow-hidden absolute w-full h-screen">
        <BackgroundSlider index={activeIndex} setIndex={setActiveIndex}>
          {({ index }: { index: number }) => {
            const modulo = index % imageNames.length;
            const imageIndex = modulo < 0 ? imageNames.length + modulo : modulo;
            return (
              <img
                draggable={false}
                className="w-full h-full object-cover"
                src={`${process.env.PUBLIC_URL}/images/${imageNames[imageIndex]}`}
              />
            );
          }}
        </BackgroundSlider>
      </div>
      <div className="z-10 bg-black bg-opacity-60 md:w-[55%] lg:w-[50%] md:h-full h-[40%] text-white md:pl-16 px-6 py-5 content-center">
        <div className="flex flex-col md:gap-16 gap-2">
          <div className="flex flex-col gap-5">
            <Logo className="lg:w-44 w-28" />
            <p className="text-xl">Навстречу приключениям</p>
          </div>
          <div className="">
            <h2 className="lg:w-2/3 lg:text-8xl md:text-6xl mob:text-5xl text-4xl font-normal">
              Скоро открытие
            </h2>
          </div>
          <div className="flex flex-col gap-2.5">
            <p>megotours.kz@gmail.com</p>
            <p>+7 701 767 42 61</p>
          </div>
          <Carousel
            className=""
            autoPlay
            autoPlaySpeed={2000}
            infinite
            renderDotsOutside={false}
            arrows={false}
            partialVisible
            minimumTouchDrag={80}
            responsive={responsive}
          >
            {imageNames.map((image, index) => {
              return (
                <div
                  className={
                    "rounded-full w-16 h-16 bg-center bg-cover cursor-pointer" +
                    (activeIndex === index ? " border-2 border-white" : "")
                  }
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/low/${image})`,
                  }}
                  // onClick={() => changeToIndexWithTimeout(index)}
                  key={index}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default App;
