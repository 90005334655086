import {
  MotionStyle,
  MotionValue,
  PanInfo,
  ValueAnimationTransition,
  animate,
  motion,
  useMotionValue,
} from "framer-motion";
import { useEffect, useMemo, useRef } from "react";

interface SlideProps {
  index: number;
  renderSlide: (props: { index: number }) => JSX.Element;
  x: MotionValue;
  onDragEnd(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void;
}

export const Slide = ({ index, renderSlide, x, onDragEnd }: SlideProps) => {
  const child = useMemo(() => renderSlide({ index }), [index, renderSlide]);

  return (
    <motion.div
      className="h-full w-full absolute"
      style={{
        x,
        left: `${index * 100}%`,
        right: `${index * 100}%`,
      }}
      draggable
      drag="x"
      dragElastic={1}
      onDragEnd={onDragEnd}
    >
      {child}
    </motion.div>
  );
};

const range = [-1, 0, 1];

const containerStyle: MotionStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
  overflowX: "hidden",
};

const transition: ValueAnimationTransition<any> = {
  type: "spring",
  bounce: 0,
};

export const BackgroundSlider = ({
  children,
  index,
  setIndex,
}: {
  children: (props: { index: number }) => JSX.Element;
  index: number;
  setIndex: (index: number) => void;
}) => {
  const x = useMotionValue(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0);

  const handleEndDrag = (e: Event, dragProps: PanInfo) => {
    const clientWidth = containerRef.current?.clientWidth || 0;

    const { offset, velocity } = dragProps;

    if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
      animate(x, calculateNewX(), transition);
      return;
    }

    if (offset.x > clientWidth / 4) {
      setIndex(index - 1);
    } else if (offset.x < -clientWidth / 4) {
      setIndex(index + 1);
    } else {
      animate(x, calculateNewX(), transition);
    }
  };

  useEffect(() => {
    const controls = animate(x, calculateNewX(), transition);
    return controls.stop;
  }, [index]);

  return (
    <motion.div ref={containerRef} style={containerStyle}>
      {range.map((rangeValue) => {
        return (
          <Slide
            key={rangeValue + index}
            x={x}
            onDragEnd={handleEndDrag}
            index={rangeValue + index}
            renderSlide={children}
          />
        );
      })}
    </motion.div>
  );
};
